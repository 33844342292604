.mobile-menu__btn {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;

  color: var(--color--antiquewhite);
  background-color: transparent;

  body.mobileMenuIsOpen & {
    z-index: 20;
  }

 @media screen and (min-width: 768px) {
    display: none;
  }
}

.mobile-menu__btn .burger-line {
  position: absolute;

  width: 28px;
  height: 2px;
  margin: 0;

  border-radius: 4px;
  background-color: currentColor;
  transition-property: height, opacity, transform;
  transition-duration: 0.4s;
}

.burger-line:first-child {
  transform: translateY(8px);
}
.burger-line:last-child {
  transform: translateY(-8px);
}

body.mobileMenuIsOpen .mobile-menu__btn {
  .burger-line:first-child {
    transform: rotate(-45deg);
  }
  .burger-line:nth-child(2n) {
    opacity: 0;
  }
  .burger-line:last-child {
    transform: rotate(45deg);
  }
}
