@import '/src/common/scss/var.scss';
@import '/src/components/mobile-menu/mobile-menu-btn.scss';
@import '/src/components/mobile-menu/mobile-menu.scss';

.header {
  height: 72px;
  padding: 16px 0;

  background-color: var(--color--accent, #1FB6FF);
}
.header__container {
  position: relative;
  
  display: flex;
  align-items: center;
  justify-content: space-between;

  &::before {
    content: '';

    position: absolute;
    bottom: -16px;
    left: 50%;
    transform: translateX(-50%);
  
    display: block;
    height: 1px;
    width: calc(100% - 2 * var(--padding, 80px));
  
    background-color: rgba(239, 239, 239, 0.3);
  }
}
.header__logo {
  display: flex;
  align-items: center;
  gap: 10px;

  color: var(--color--primary, #143241);
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}

.header-nav {
  @media screen and (max-width: calc($tablet - 1px)) {
    display: none;
  }
}
.header-nav__list {
  display: flex;
  align-items: center;
  gap: 12px;
}
.header-nav__item {
  &:first-of-type {
    flex: 0 0 80px;

    // @media screen and (max-width: calc($tablet - 1px)) {
    //   display: none;
    // }
  }

  &:last-of-type {
    flex: 0 0 140px;
  }
}
.header-nav__link,
.mobile-menu-nav__link {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  padding: 8px 16px;

  color: var(--color--primary, #143241);
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;

  &.current::after {
    content: "";

    position: absolute;
    bottom: -20px;
    left: 0;

    display: block;
    height: 4px;
    width: 100%;

    background-color: var(--color--primary, #143241);
    border-radius: 16px 16px 0px 0px;
  }
}
.header-nav__link--contact,
.mobile-menu-nav__link--contact {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 8px 16px;

  color: #000;

  background-color: #fff;
  border-radius: 8px;
}

.mobile-menu-nav__list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
.mobile-menu-nav__item {
  width: 100%;
}

.hero.section {
  min-height: 400px;
  padding: 32px 0 90px;

  background: url("/src/images/bg-hero-2.svg"), linear-gradient( to top, transparent 90px, #1FB6FF 90px 100%);
  background-repeat: no-repeat;
  background-size: 1820px 90px, cover;
  background-position: bottom;

  @media screen and (min-width: $desktopXL) {
    background-size: 120%, cover;
  }
}

.hero-previews__list {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (min-width: $tablet) {
    --gap: 32px;
  }
  @media screen and (min-width: $laptop) {
    flex-direction: row;
  }
  @media screen and (min-width: $desktop) {
    --gap: 40px;
  }
  @media screen and (min-width: $desktopL) {
    --gap: 52px;
  }
  @media screen and (min-width: $desktopXL) {
    --gap: 64px;
  }

  @media screen and (min-width: $desktop) {
    background-size: 120%, cover;
  }
}
.hero-previews__item {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;

  border-radius: 12px;
  background: linear-gradient(0deg, #EFEFEF, #EFEFEF), linear-gradient(0deg, rgba(250, 249, 255, 0.3), rgba(250, 249, 255, 0.3));
  border: 1px solid #EFEFEF;
  box-shadow: 0px 2px 2px 0px #1FB6FF26;


  background: linear-gradient(135deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 47.5%, #FFFFFF 100%);
  background: #FFFFFF1A;
  background-blend-mode: overlay;

  // min-height: 212px;
  // @media screen and (min-width: $mobile) {
  //   min-height: 280px;
  // }
  // @media screen and (min-width: $tablet) {
  //   min-height: 405px;
  // }
  // @media screen and (min-width: $laptop) {
  //   min-height: 222px;
  // }
  // @media screen and (min-width: $desktop) {
  //   min-height: 250px;
  // }
  // @media screen and (min-width: $desktopXL) {
  //   min-height: 264px;
  // }
}

.hero-previews_img {
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 12px;
}
.hero-previews__desc {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.hero-previews__title {
  color: var(--color--primary, #143241);
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
}
.hero-previews__link {
  flex: 0 0 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 72px;
  height: 28px;

  color: #000;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;

  border-radius: 8px;
  background: linear-gradient(0deg, #EFEFEF, #EFEFEF), linear-gradient(0deg, rgba(250, 249, 255, 0.3), rgba(250, 249, 255, 0.3));
  border: 1px solid #EFEFEF;
  box-shadow: 0px 2px 2px 0px #1FB6FF26;


  background: linear-gradient(135deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 47.5%, #FFFFFF 100%);
  background: #FFFFFF1A;
  background-blend-mode: overlay;

  background: rgba(250, 249, 255, 0.6);
}

.apps__list {
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.app {
  display: flex;
  --gap: 16px;
  gap: var(--gap);

  display: flex;
  flex-direction: column;

  @media screen and (min-width: $tablet) {
    --gap: 32px;
  }
  @media screen and (min-width: $laptop) {
    flex-direction: row;
  }
  @media screen and (min-width: $desktop) {
    --gap: 40px;
  }
  @media screen and (min-width: $desktopL) {
    --gap: 52px;
  }
  @media screen and (min-width: $desktopXL) {
    --gap: 64px;
  }
}

.app__box {
  flex: 0 0 calc((100% - var(--gap)) / 2);
}

.app__box {
  // min-height: 698px;
  @media screen and (min-width: $tablet) {
    // padding: 0 32px;
  }
  @media screen and (min-width: $laptop) {
    min-height: 698px;
  }
}

.app__box--img {
  display: flex;
  align-items: center;

  max-height: 698px;
  padding: 32px 0;

  border-radius: 12px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (min-width: $laptop) {
    padding: 28px 0;
    max-width: calc((100% - var(--gap)) / 2);
  }
}
.app__box--img-1 {
  background-image: url('/src/images/bg-app-1-x2.webp');

  @media (min-device-pixel-ratio: 2), (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx) {
    background-image: url('/src/images/bg-app-1-x3.webp');
  }

  @media (min-device-pixel-ratio: 3), (-webkit-min-device-pixel-ratio: 3), (min-resolution: 288dpi), (min-resolution: 3dppx) {
    background-image: url('/src/images/bg-app-1-x4.webp');
  }
}
.app__box--img-2 {
  background-image: url('/src/images/bg-app-2-x2.webp');

  @media (min-device-pixel-ratio: 2), (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx) {
    background-image: url('/src/images/bg-app-2-x3.webp');
  }

  @media (min-device-pixel-ratio: 3), (-webkit-min-device-pixel-ratio: 3), (min-resolution: 288dpi), (min-resolution: 3dppx) {
    background-image: url('/src/images/bg-app-2-x4.webp');
  }
}
.app__box--img-3 {
  background-image: url('/src/images/bg-app-3-x2.webp');

  @media (min-device-pixel-ratio: 2), (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx) {
    background-image: url('/src/images/bg-app-3-x3.webp');
  }

  @media (min-device-pixel-ratio: 3), (-webkit-min-device-pixel-ratio: 3), (min-resolution: 288dpi), (min-resolution: 3dppx) {
    background-image: url('/src/images/bg-app-3-x4.webp');
  }
}

.apps-swiper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.swiper {
  overflow: hidden;
}
.apps-swiper__list {
  align-items: center;
}
.apps-swiper__item {
  display: flex;
  align-items: center;
  padding: 0 32px;

  @media screen and (min-width: $laptop) {
    padding: 0 80px;
  }
}
.apps-swiper__img {
  width: auto;
  // height: auto;
  max-height: 500px;
  margin: 0 auto;

  object-fit: cover;
  border-radius: 12px;
}
.apps-swiper__btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  // margin: 24px 0 40px;
  margin: 40px 0 40px;
}
.apps-swiper__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;

  border-radius: 50%;
  // border: 1px solid linear-gradient(180deg, rgba(87, 255, 144, 0.6) 0%, rgba(20, 201, 125, 0.5) 100%);;
  box-shadow: 0px 10px 10px 0px #0000001A;
  box-shadow: 0px 4px 4px 0px #0000000D;
  box-shadow: 0px 1px 0px 0px #0000000D;

  background: #FFFFFF1A;
  background-blend-mode: overlay;
  background: linear-gradient(45deg, rgba(255, 255, 255, 0) 33.33%, #FFFFFF 95%);
  background: linear-gradient(180deg, rgba(101, 255, 87, 0.6) 0%, rgba(20, 201, 168, 0.5) 100%);

  border: 1px solid linear-gradient(45deg, rgba(255, 255, 255, 0) 33.33%, #FFFFFF 95%),
  linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));


  .apps-swiper--2 & {
    background: #FFFFFF1A;
    background-blend-mode: overlay;
    background: linear-gradient(45deg, rgba(255, 255, 255, 0) 33.33%, #FFFFFF 95%);
    background: linear-gradient(180deg, rgba(87, 124, 255, 0.6) 0%, rgba(20, 38, 201, 0.5) 100%);  
    
    border: 1px solid linear-gradient(45deg, rgba(255, 255, 255, 0) 33.33%, #FFFFFF 95%),
    linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
  }
  
}
.apps-swiper-pagination {
  position: absolute;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.swiper-pagination-bullet {
  width: 8px;
  height: 8px;

  transition: 200ms linear;
  opacity: 0.5; 

  .apps-swiper--1 & { background-color: #40F573;}
  .apps-swiper--2 & { background-color: #40CAF5;}
  .apps-swiper--3 & { background-color: #40F573;}
}
.swiper-pagination-bullet-active {
  width: 16px;
  height: 16px;

  opacity: 1; 
}

.app__btn-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px 16px;

  color: var(--color--primary, #192F3A);
  font-size: 18px;
  font-weight: 500;

  background-color: #FAF9FF;
  border-radius: 12px;

  & svg {
    transition: 200ms linear;
    transform: rotate(-180deg);
  }

  &.isActive svg {
    transform: rotate(0deg);
  }
}
.app__title {
  margin: 24px 0 24px;

  color: #020210;
  font-size: 34px;
  line-height: 1.5;
  font-weight: 500;

  @media screen and (min-width: $mobileL) {
    font-size: 36px;
  }
  @media screen and (min-width: $mobile) {
    font-size: 44px;
  }
  @media screen and (min-width: $tablet) {
    font-size: 48px;
  }
  @media screen and (min-width: $laptop) {
    margin: 56px 0 24px;

    font-size: 46px;
    line-height: 70px;
  }
  @media screen and (min-width: $desktop) {
    font-size: 52px;
  }
  @media screen and (min-width: $desktopXL) {
    font-size: 56px;
  }
}
.app__desc {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-height: 256px;

  overflow: hidden;
  transition: max-height 300ms linear;
}
.app__text {
  color: #4A4A53;
  font-size: 18px;
  line-height: 32px;

  @media screen and (min-width: $tablet) {
    font-size: 20px;
  }
  @media screen and (min-width: $tablet) {
    font-size: 22px;
  }
}
.app__text span {
  display: block;
  // width: 100%;
}

.app__link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
  height: 72px;
  margin-top: 40px;

  color: #fff;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;

  border-radius: 12px;
  background-color: var(--color--accent, #1FB6FF);


  @media screen and (min-width: $tablet) {
    --gap: 32px;
  }
  @media screen and (min-width: $laptop) {
    max-width: 328px;
  }
}

