@media screen and (width >= 768px) {
  .mobile-menu__btn {
    display: none;
  }
}

:root {
  --color--primary: #143241;
  --color--accent: #1fb6ff;
  --items: 1;
  --gap: 12px;
}

.mobile-menu__btn {
  color: var(--color--antiquewhite);
  background-color: #0000;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  display: flex;
  position: relative;
}

body.mobileMenuIsOpen .mobile-menu__btn {
  z-index: 20;
}

@media screen and (width >= 768px) {
  .mobile-menu__btn {
    display: none;
  }
}

.mobile-menu__btn .burger-line {
  background-color: currentColor;
  border-radius: 4px;
  width: 28px;
  height: 2px;
  margin: 0;
  transition-property: height, opacity, transform;
  transition-duration: .4s;
  position: absolute;
}

.burger-line:first-child {
  transform: translateY(8px);
}

.burger-line:last-child {
  transform: translateY(-8px);
}

body.mobileMenuIsOpen .mobile-menu__btn .burger-line:first-child {
  transform: rotate(-45deg);
}

body.mobileMenuIsOpen .mobile-menu__btn .burger-line:nth-child(2n) {
  opacity: 0;
}

body.mobileMenuIsOpen .mobile-menu__btn .burger-line:last-child {
  transform: rotate(45deg);
}

.mobile-menu {
  background-color: var(--color--accent);
  z-index: 3;
  flex-direction: column;
  width: 100%;
  height: 100%;
  transition: all .2s linear;
  display: flex;
  position: fixed;
  top: 72px;
  right: 0;
  overflow-y: hidden;
}

.mobile-menu.isHidden {
  right: -101%;
}

.mobile-menu.isHidden button, .mobile-menu.isHidden a {
  pointer-events: none;
  visibility: hidden;
}

.mobile-menu .nav__link {
  flex-wrap: 800;
  font-size: 28px;
}

.mobile-menu__body {
  padding: 16px 0;
}

.header {
  background-color: var(--color--accent, #1fb6ff);
  height: 72px;
  padding: 16px 0;
}

.header__container {
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.header__container:before {
  content: "";
  width: calc(100% - 2 * var(--padding, 80px));
  background-color: #efefef4d;
  height: 1px;
  display: block;
  position: absolute;
  bottom: -16px;
  left: 50%;
  transform: translateX(-50%);
}

.header__logo {
  color: var(--color--primary, #143241);
  align-items: center;
  gap: 10px;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  display: flex;
}

@media screen and (width <= 767px) {
  .header-nav {
    display: none;
  }
}

.header-nav__list {
  align-items: center;
  gap: 12px;
  display: flex;
}

.header-nav__item:first-of-type {
  flex: 0 0 80px;
}

.header-nav__item:last-of-type {
  flex: 0 0 140px;
}

.header-nav__link, .mobile-menu-nav__link {
  color: var(--color--primary, #143241);
  justify-content: center;
  align-items: center;
  height: 34px;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  display: flex;
  position: relative;
}

.header-nav__link.current:after, .mobile-menu-nav__link.current:after {
  content: "";
  background-color: var(--color--primary, #143241);
  border-radius: 16px 16px 0 0;
  width: 100%;
  height: 4px;
  display: block;
  position: absolute;
  bottom: -20px;
  left: 0;
}

.header-nav__link--contact, .mobile-menu-nav__link--contact {
  color: #000;
  background-color: #fff;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 8px 16px;
  display: flex;
}

.mobile-menu-nav__list {
  flex-direction: column;
  align-items: center;
  gap: 12px;
  display: flex;
}

.mobile-menu-nav__item {
  width: 100%;
}

.hero.section {
  background-color: #0000;
  background-image: url("bg-hero-2.6f35ed28.svg"), linear-gradient(to top, #0000 90px, #1fb6ff 90px 100%);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 1820px 90px, cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  min-height: 400px;
  padding: 32px 0 90px;
}

@media screen and (width >= 1600px) {
  .hero.section {
    background-size: 120%, cover;
  }
}

.hero-previews__list {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

@media screen and (width >= 768px) {
  .hero-previews__list {
    --gap: 32px;
  }
}

@media screen and (width >= 1024px) {
  .hero-previews__list {
    flex-direction: row;
  }
}

@media screen and (width >= 1200px) {
  .hero-previews__list {
    --gap: 40px;
  }
}

@media screen and (width >= 1440px) {
  .hero-previews__list {
    --gap: 52px;
  }
}

@media screen and (width >= 1600px) {
  .hero-previews__list {
    --gap: 64px;
  }
}

@media screen and (width >= 1200px) {
  .hero-previews__list {
    background-size: 120%, cover;
  }
}

.hero-previews__item {
  background-blend-mode: overlay;
  background: #ffffff1a;
  border: 1px solid #efefef;
  border-radius: 12px;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  display: flex;
  box-shadow: 0 2px 2px #1fb6ff26;
}

.hero-previews_img {
  object-fit: contain;
  border-radius: 12px;
  width: 100%;
  height: auto;
}

.hero-previews__desc {
  justify-content: space-between;
  gap: 16px;
  display: flex;
}

.hero-previews__title {
  color: var(--color--primary, #143241);
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
}

.hero-previews__link {
  color: #000;
  background-blend-mode: overlay;
  background: #faf9ff99;
  border: 1px solid #efefef;
  border-radius: 8px;
  flex: 0 0 72px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 72px;
  height: 28px;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  box-shadow: 0 2px 2px #1fb6ff26;
}

.apps__list {
  flex-direction: column;
  gap: 64px;
  display: flex;
}

.app {
  --gap: 16px;
  gap: var(--gap);
  flex-direction: column;
  display: flex;
}

@media screen and (width >= 768px) {
  .app {
    --gap: 32px;
  }
}

@media screen and (width >= 1024px) {
  .app {
    flex-direction: row;
  }
}

@media screen and (width >= 1200px) {
  .app {
    --gap: 40px;
  }
}

@media screen and (width >= 1440px) {
  .app {
    --gap: 52px;
  }
}

@media screen and (width >= 1600px) {
  .app {
    --gap: 64px;
  }
}

.app__box {
  flex: 0 0 calc((100% - var(--gap)) / 2);
}

@media screen and (width >= 1024px) {
  .app__box {
    min-height: 698px;
  }
}

.app__box--img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;
  align-items: center;
  max-height: 698px;
  padding: 32px 0;
  display: flex;
}

@media screen and (width >= 1024px) {
  .app__box--img {
    max-width: calc((100% - var(--gap)) / 2);
    padding: 28px 0;
  }
}

.app__box--img-1 {
  background-image: url("bg-app-1-x2.047bbc4d.webp");
}

@media (min-device-pixel-ratio: 2), (-webkit-device-pixel-ratio >= 2), (resolution >= 192dpi), (resolution >= 2x) {
  .app__box--img-1 {
    background-image: url("bg-app-1-x3.05b0657b.webp");
  }
}

@media (min-device-pixel-ratio: 3), (-webkit-device-pixel-ratio >= 3), (resolution >= 288dpi), (resolution >= 3x) {
  .app__box--img-1 {
    background-image: url("bg-app-1-x4.4f8a9647.webp");
  }
}

.app__box--img-2 {
  background-image: url("bg-app-2-x2.7df0b25d.webp");
}

@media (min-device-pixel-ratio: 2), (-webkit-device-pixel-ratio >= 2), (resolution >= 192dpi), (resolution >= 2x) {
  .app__box--img-2 {
    background-image: url("bg-app-2-x3.736e2f9f.webp");
  }
}

@media (min-device-pixel-ratio: 3), (-webkit-device-pixel-ratio >= 3), (resolution >= 288dpi), (resolution >= 3x) {
  .app__box--img-2 {
    background-image: url("bg-app-2-x4.d24f7182.webp");
  }
}

.app__box--img-3 {
  background-image: url("bg-app-3-x2.8e71b6d2.webp");
}

@media (min-device-pixel-ratio: 2), (-webkit-device-pixel-ratio >= 2), (resolution >= 192dpi), (resolution >= 2x) {
  .app__box--img-3 {
    background-image: url("bg-app-3-x3.2823a9d4.webp");
  }
}

@media (min-device-pixel-ratio: 3), (-webkit-device-pixel-ratio >= 3), (resolution >= 288dpi), (resolution >= 3x) {
  .app__box--img-3 {
    background-image: url("bg-app-3-x4.8967de37.webp");
  }
}

.apps-swiper {
  flex-direction: column;
  justify-content: center;
  height: 100%;
  display: flex;
}

.swiper {
  overflow: hidden;
}

.apps-swiper__list {
  align-items: center;
}

.apps-swiper__item {
  align-items: center;
  padding: 0 32px;
  display: flex;
}

@media screen and (width >= 1024px) {
  .apps-swiper__item {
    padding: 0 80px;
  }
}

.apps-swiper__img {
  object-fit: cover;
  border-radius: 12px;
  width: auto;
  max-height: 500px;
  margin: 0 auto;
}

.apps-swiper__btns {
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin: 40px 0;
  display: flex;
}

.apps-swiper__btn {
  background-blend-mode: overlay;
  border: 1px solid linear-gradient(45deg, #fff0 33.33%, #fff 95%), linear-gradient(0deg, #ffffff1a, #ffffff1a);
  background: linear-gradient(#65ff5799 0%, #14c9a880 100%);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  display: flex;
  box-shadow: 0 1px #0000000d;
}

.apps-swiper--2 .apps-swiper__btn {
  background-blend-mode: overlay;
  border: 1px solid linear-gradient(45deg, #fff0 33.33%, #fff 95%), linear-gradient(0deg, #ffffff1a, #ffffff1a);
  background: linear-gradient(#577cff99 0%, #1426c980 100%);
}

.apps-swiper-pagination {
  justify-content: center;
  align-items: flex-end;
  display: flex;
  position: absolute;
  bottom: 0;
}

.swiper-pagination-bullet {
  opacity: .5;
  width: 8px;
  height: 8px;
  transition: all .2s linear;
}

.apps-swiper--1 .swiper-pagination-bullet {
  background-color: #40f573;
}

.apps-swiper--2 .swiper-pagination-bullet {
  background-color: #40caf5;
}

.apps-swiper--3 .swiper-pagination-bullet {
  background-color: #40f573;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  width: 16px;
  height: 16px;
}

.app__btn-details {
  color: var(--color--primary, #192f3a);
  background-color: #faf9ff;
  border-radius: 12px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px 16px;
  font-size: 18px;
  font-weight: 500;
  display: flex;
}

.app__btn-details svg {
  transition: all .2s linear;
  transform: rotate(-180deg);
}

.app__btn-details.isActive svg {
  transform: rotate(0);
}

.app__title {
  color: #020210;
  margin: 24px 0;
  font-size: 34px;
  font-weight: 500;
  line-height: 1.5;
}

@media screen and (width >= 425px) {
  .app__title {
    font-size: 36px;
  }
}

@media screen and (width >= 480px) {
  .app__title {
    font-size: 44px;
  }
}

@media screen and (width >= 768px) {
  .app__title {
    font-size: 48px;
  }
}

@media screen and (width >= 1024px) {
  .app__title {
    margin: 56px 0 24px;
    font-size: 46px;
    line-height: 70px;
  }
}

@media screen and (width >= 1200px) {
  .app__title {
    font-size: 52px;
  }
}

@media screen and (width >= 1600px) {
  .app__title {
    font-size: 56px;
  }
}

.app__desc {
  flex-direction: column;
  gap: 24px;
  max-height: 256px;
  transition: max-height .3s linear;
  display: flex;
  overflow: hidden;
}

.app__text {
  color: #4a4a53;
  font-size: 18px;
  line-height: 32px;
}

@media screen and (width >= 768px) {
  .app__text {
    font-size: 22px;
  }
}

.app__text span {
  display: block;
}

.app__link {
  color: #fff;
  background-color: var(--color--accent, #1fb6ff);
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  height: 72px;
  margin-top: 40px;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  display: flex;
}

@media screen and (width >= 768px) {
  .app__link {
    --gap: 32px;
  }
}

@media screen and (width >= 1024px) {
  .app__link {
    max-width: 328px;
  }
}
/*# sourceMappingURL=index.c22b0fc5.css.map */
