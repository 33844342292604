@import '/src/common/scss/var.scss';
@import './mobile-menu-btn.scss';

.mobile-menu {
  position: fixed;
  top: 72px;
  right: 0;

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  background-color: var( --color--accent);
  transition: all 200ms linear;
  overflow-y: scroll;
  overflow-y: hidden;
  z-index: 3;

  &.isHidden {
    right: -101%;

    button, a {
      pointer-events: none;
      visibility: hidden;
    }
  }

  .nav__link {
    font-size: 28px;
    flex-wrap: 800;
  }

  // @include breakpoint-min($small) {
  //   width: 320px;
  // }
}

.mobile-menu__body {
  padding: 16px 0;
}

